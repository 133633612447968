import React, { useRef, useEffect, useCallback } from "react";
import * as ReactDOM from "react-dom";
import Lottie from "react-lottie";
import animationData from "../media/icons/microphone.json";
import { useLongPress } from "use-long-press";
import "../css/micControl.css";
import { ReactComponent as MyCustomMic } from "../media/icons/mic.svg";

export default function MicControl({
  startSpeechListening,
  stopSpeechListening,
  listeningOn,
  setLongPress,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
      className: "micControl",
    },
  };

  const callback = useCallback((event) => {
    //
  }, []);
  const bind = useLongPress(callback, {
    onStart: (event) => startSpeechListening(),
    onFinish: (event) => stopSpeechListening(),
    onCancel: (event) => "",
    onMove: (event) => console.log("Detected mouse or touch movement"),
    filterEvents: (event) => true, // All events can potentially trigger long press (same as 'undefined')
    threshold: 500, // In milliseconds
    captureEvent: true, // Event won't get cleared after React finish processing it
    cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
    detect: "pointer", // Default option
  });

  const wrapperRef = useRef(null);
  useEffect(() => {
    const handleClick = (event) => {
      if (listeningOn) {
        stopSpeechListening();
      } else {
        startSpeechListening();
      }
    };

    const element = ReactDOM.findDOMNode(wrapperRef.current);
    element.addEventListener("click", handleClick);

    return () => {
      element.removeEventListener("click", handleClick);
    };
  });

  let icon = <MyCustomMic ref={wrapperRef} className="micControl-icon" />;
  if (listeningOn) {
    icon = <Lottie options={defaultOptions} ref={wrapperRef} />;
  }

  return <div className="animation-container">
    <>{icon}</>
    <div className="micControl-label-container">
      <p style={{
        color: "#ffffffb3",
        fontSize:12
      }}>La IA puede cometer errores. Verifica las respuestas.</p>
    </div>
  </div>;
}
